@import "../../styles/variables/index.scss";
@import "../../styles/variables/_colors.scss";
@import "../../styles/_mixins.scss";

.iconButton {
  opacity: 0.5;
  padding-top: 2px;
  &:hover {
    opacity: 1;
  }

  svg {
    path {
      fill: var(--toastr-close-button-color);
    }
  }
}

:global(.rtl) {
  :local {
    .toast {
      --toast-transform: translate3d(-150%, 0, 0);
    }
  }
}

.toast {
  z-index: 9999;
  -webkit-transform: translateZ(9999px);
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: var(--toast-text-color-comp);
  top: var(--toast-top-offset);
  inset-inline-end: 24px;
  margin-top: 0;
  -webkit-tap-highlight-color: var(--toast-tap-highlight);

  :global {
    .SlideIn {
      :local {
        animation: SlideIn 0.7s ease-out;
      }
    }

    @keyframes SlideIn {
      from {
        transform: var(--toast-transform);
      }

      50% {
        visibility: hidden;
        transform: translate3d(0, 0, 0);
      }
    }

    .SlideOut {
      :local {
        animation: SlideOut 0.3s ease-out both;
      }
    }

    @keyframes SlideOut {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
      }
    }

    .Toastify__progress-bar--animated {
      :local {
        animation: Toastify__trackProgress linear 1 forwards;
      }
    }

    @keyframes Toastify__trackProgress {
      0% {
        transform: scaleX(1);
      }
      to {
        transform: scaleX(0);
      }
    }

    .Toastify__toast-body {
      display: flex;
      align-items: center;

      overflow-wrap: anywhere;
      margin: auto 0;
      -ms-flex: 1;
      flex: 1;
    }

    .Toastify__close-button {
      color: var(--toast-close-button-color);
      font-weight: 700;
      font-size: 14px;
      background: transparent;
      outline: none;
      border: none;
      padding: 0;
      cursor: pointer;
      opacity: 0.7;
      transition: 0.3s ease;
      -ms-flex-item-align: start;
      align-self: flex-start;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    .Toastify__toast {
      box-sizing: border-box;
      margin-bottom: 1rem;
      box-shadow: var(--toast-box-shadow);
      display: flex;
      justify-content: space-between;
      max-height: 800px;
      overflow: hidden;
      cursor: pointer;

      border-radius: 6px;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      color: var(--toast-text-color);
      margin: 0 0 12px;
      padding: 12px;
      min-height: 32px;
      font-size: 12px;
      font-weight: normal;
      width: 100%;
      inset-inline-end: 0;

      transition: 0.3s;
    }

    .Toastify__toast--success {
      background-color: var(--toast-success-bg);
      border: var(--toast-success-border);

      &:hover {
        background-color: var(--toast-success-hover-bg);
      }
    }

    .Toastify__toast--error {
      background-color: var(--toast-error-bg);
      border: var(--toast-error-border);

      &:hover {
        background-color: var(--toast-error-hover-bg);
      }
    }

    .Toastify__toast--info {
      background-color: var(--toast-info-bg);
      border: var(--toast-info-border);

      &:hover {
        background-color: var(--toast-info-hover-bg);
      }
    }

    .Toastify__toast--warning {
      background-color: var(--toast-warning-bg);
      border: var(--toast-warning-border);

      &:hover {
        background-color: var(--toast-warning-hover-bg);
      }
    }

    @include tablet-and-below {
      inset-inline-end: 16px;

      .Toastify__toast {
        position: absolute;

        &:nth-child(1) {
          z-index: 3;
          top: 0px;
        }
        &:nth-child(2) {
          z-index: 2;
          top: 8px;
        }
        &:nth-child(3) {
          z-index: 1;
          top: 16px;
        }
      }
    }

    @include mobile {
      inset-inline: 0;
      margin: auto;
      width: 100%;
      max-width: calc(100% - 32px);

      @keyframes SlideIn {
        from {
          transform: translate3d(0, -150%, 0);
        }

        50% {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

.toastContent {
  display: flex;
  align-items: center;

  :global {
    .icon-wrapper {
      align-self: start;
      display: flex;

      svg {
        width: 16px;
        min-width: 16px;
        height: 16px;
        min-height: 16px;
      }
    }

    .toast-title {
      font-weight: 600;
      margin: 0;
      margin-bottom: 5px;
      line-height: 16px;
      font-size: 12px;
    }

    .toast-text-container {
      margin: 0 15px;
    }

    .toast-text {
      line-height: 1.3;
      align-self: center;
      font-size: 12px;
      color: var(--toastr-text-color);
      word-break: break-word;
    }
  }

  &[data-type="success"] {
    :global {
      .toast-title {
        color: var(--toastr-title-success-color);
      }
      .icon-wrapper {
        path {
          fill: var(--toastr-svg-success-color);
        }
      }
    }
  }

  &[data-type="error"] {
    :global {
      .toast-title {
        color: var(--toastr-title-error-color);
      }
      .icon-wrapper {
        path {
          fill: var(--toastr-svg-error-color);
        }
      }
    }
  }

  &[data-type="warning"] {
    :global {
      .toast-title {
        color: var(--toastr-title-warning-color);
      }
      .icon-wrapper {
        path {
          fill: var(--toastr-svg-warning-color);
        }
      }
    }
  }

  &[data-type="info"] {
    :global {
      .toast-title {
        color: var(--toastr-title-info-color);
      }
      .icon-wrapper {
        path {
          fill: var(--toastr-svg-info-color);
        }
      }
    }
  }
}
