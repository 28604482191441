@import "./variables/_colors.scss";
@import "./_mixins.scss";

@mixin common-input-color-styles {
  background-color: var(--input-bg);
  color: var(--input-color);
  caret-color: var(--input-color);
  -webkit-text-fill-color: var(--input-color);

  border-radius: var(--input-border-radius);
  -moz-border-radius: var(--input-border-radius);
  -webkit-border-radius: var(--input-border-radius);

  box-shadow: var(--input-box-shadow);
  box-sizing: var(--input-box-sizing);
  border: var(--input-border);
  border-color: var(--input-border-color);

  -webkit-background-clip: text !important;

  &[data-error="true"] {
    border-color: var(--input-error-border);
    &:hover {
      border-color: var(--input-error-border-hover);
    }
    &:focus,
    &[data-focus="true"] {
      border-color: var(--input-error-border-focus);
    }
  }

  &[data-warning="true"] {
    border-color: var(--input-warning-border);
    &:hover {
      border-color: var(--input-warning-border-hover);
    }
    &:focus,
    &[data-focus="true"] {
      border-color: var(--input-warning-border-focus);
    }
  }
  &[data-disabled="true"],
  &:disabled {
    background-color: var(--input-disabled-bg);
    color: var(--input-disabled-color);
    caret-color: var(--input-disabled-color);
    -webkit-text-fill-color: var(--input-disabled-color);

    border-color: var(--input-disabled-border);
    cursor: default;

    &::placeholder {
      color: var(--text-input-placeholder-disabled-color);
      -webkit-text-fill-color: var(--text-input-placeholder-disabled-color);
    }
    &::-webkit-input-placeholder {
      color: var(--text-input-placeholder-disabled-color);
      -webkit-text-fill-color: var(--text-input-placeholder-disabled-color);
    }
    &::-moz-placeholder {
      color: var(--text-input-placeholder-disabled-color);
      -webkit-text-fill-color: var(--text-input-placeholder-disabled-color);
    }
    &:-ms-input-placeholder {
      color: var(--text-input-placeholder-disabled-color);
      -webkit-text-fill-color: var(--text-input-placeholder-disabled-color);
    }
    &:-moz-placeholder {
      color: var(--text-input-placeholder-disabled-color);
      -webkit-text-fill-color: var(--text-input-placeholder-disabled-color);
    }
  }

  &::placeholder {
    color: var(--text-input-placeholder-color);
    -webkit-text-fill-color: var(--text-input-placeholder-color);
    @include no-user-select;
  }

  &::-webkit-input-placeholder {
    color: var(--text-input-placeholder-color);
    -webkit-text-fill-color: var(--text-input-placeholder-color);
    @include no-user-select;
  }

  &::-moz-placeholder {
    color: var(--text-input-placeholder-color);
    -webkit-text-fill-color: var(--text-input-placeholder-color);
    @include no-user-select;
  }

  &:-ms-input-placeholder {
    color: var(--text-input-placeholder-color);
    -webkit-text-fill-color: var(--text-input-placeholder-color);
    @include no-user-select;
  }

  &:-moz-placeholder {
    color: var(--text-input-placeholder-color);
    -webkit-text-fill-color: var(--text-input-placeholder-color);
    @include no-user-select;
  }

  &:not(:disabled):not(&[data-disabled="true"]) {
    &:hover {
      border-color: var(--input-border-hover);
    }
    &:focus,
    &[data-focus="true"] {
      border-color: var(--input-border-focus);
    }
    cursor: text;
  }

  &[readonly] {
    cursor: default;
  }

  &:placeholder-shown {
    direction: var(--interface-direction);
  }

  // RTL Support
  [dir="rtl"] & {
    &::placeholder {
      text-align: right;
    }

    &[type="tel"]:placeholder-shown {
      direction: ltr;
    }

    &[type="search"] {
      unicode-bidi: plaintext;
    }
  }
}

@mixin common-input-styles {
  @include common-input-color-styles;

  &[data-size="base"] {
    width: var(--input-width-base);
    line-height: var(--text-input-line-height-base);
    font-size: var(--text-input-font-size-base);
    padding: var(--text-input-padding-base);
  }
  &[data-size="middle"] {
    width: var(--input-width-middle);
    line-height: var(--text-input-line-height-middle);
    font-size: var(--text-input-font-size-middle);
    padding: var(--text-input-padding-middle);
  }
  &[data-size="big"] {
    width: var(--input-width-big);
    line-height: var(--text-input-line-height-big);
    font-size: var(--text-input-font-size-big);
    padding: var(--text-input-padding-big);
  }
  &[data-size="huge"] {
    width: var(--input-width-huge);
    line-height: var(--text-input-line-height-huge);
    font-size: var(--text-input-font-size-huge);
    padding: var(--text-input-padding-huge);
  }
  &[data-size="large"] {
    width: var(--input-width-large);
    line-height: var(--text-input-line-height-large);
    font-size: var(--text-input-font-size-large);
    padding: var(--text-input-padding-large);
  }

  &[data-scale="true"] {
    width: 100%;
  }
}
